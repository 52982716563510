import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, Outlet, createBrowserRouter } from "react-router-dom";
import * as PAGES from "./pages";
import { ROUTES } from "./routes";
import { Footer, Nav } from "./sections";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./index.css";
import "./fonts/GrenobleSerial.otf";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const Layout = () => {
  return (
    <div className="bg-cream w-screen h-screen overflow-y-auto overflow-x-hidden flex flex-col font-grenoble">
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Boosted Bouillon</title>
        </Helmet>
        <Nav />
        <Outlet />
        <Footer />
      </HelmetProvider>
    </div>
  );
};

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: ROUTES.root,
        element: <PAGES.Landing />,
      },
      {
        path: ROUTES.about,
        element: <PAGES.About />,
      },
      {
        path: ROUTES.nutrition,
        element: <PAGES.Nutrition />,
      },
      {
        path: ROUTES.contact,
        element: <PAGES.Contact />,
      },
      {
        path: ROUTES.product,
        element: <PAGES.Product />,
      },
      { path: "*", element: <PAGES.NotFound /> },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
