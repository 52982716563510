import React from "react";

interface PI extends React.HTMLAttributes<HTMLDivElement> {
  name: string; // The name of the person
  role?: string; // The person's role in the company
  pronouns?: string; // The person's pronouns
  imgString: string; // The path to the image of the person
}

export const PersonImg: React.FC<PI> = ({
  name,
  className,
  imgString,
  pronouns,
  role,
}) => {
  return (
    // Bring className in from parent, allows for customizing
    <div className={className || ""}>
      {/* Image of the person */}
      <img
        src={imgString}
        alt={name}
        className="w-[360px] h-auto mx-auto border-4 border-dashed border-mustard rounded-2xl max-w-[95%] bg-white"
      />

      {/* Name and pronouns of the person */}
      <p className="text-center my-4">
        {name} <span className="text-[75%]">({pronouns || "She/her"})</span>
      </p>

      {/* Role of the person */}
      <p className="text-center underline decoration-mustard mb-4 mt-0">
        {role || "Founder"}
      </p>
    </div>
  );
};
